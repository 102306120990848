<template>
  <div class="row">
    <!-- Contact Info & Business Hours -->
    <div class="col-12 col-lg-6 order-2 order-lg-1 info-business-container d-flex justify-content-center py-5">
      <div>
        <h3 class="text-green montserrat-large py-4">Contact Info</h3>
        <p class="text-green montserrat-regular pb-2"><img src="../assets/location-icon.svg" class="img-fluid icon">Long Island, NY</p>
        <p class="text-green montserrat-regular pb-2"><img src="../assets/location-icon.svg" class="img-fluid icon">Miami, FL</p>
        <a href="sms: 5163660493"><p class="text-green montserrat-regular pb-2"><img src="../assets/text-icon.svg" class="img-fluid icon">516-366-0493 <strong>(Text only)</strong></p></a>
        <a href="mailto: info@getblaked.com"><p class="text-green montserrat-regular pb-2"><img src="../assets/email-icon.svg" class="img-fluid icon">info@getblaked.com</p></a>

        <h3 class="text-green montserrat-large py-4">Business Hours</h3>
        <p class="text-green montserrat-regular pb-2">Mon - Fri: 9am - 5pm</p>
        <p class="text-green montserrat-regular pb-2">Saturday: 9am - 5pm</p>
        <p class="text-green montserrat-regular pb-2"><strong>NOTE:</strong> All orders ship the <br>Monday after purchase</p>
      </div>
    </div>
    <!-- Contact Form -->
    <div class="col-12 col-lg-6 order-1 order-lg-2 form-container d-flex align-items-center flex-column py-5">
      <h3 class="text-green montserrat-x-large py-lg-4 py-2">Contact Us</h3>

      <form @submit.prevent="handleSubmit" class="d-flex flex-column w-100 px-lg-5 px-1">
        <!-- Full Name -->
        <label class="text-green montserrat-regular pt-3 pb-1 mx-2">Full Name *</label>
        <input type="text" placeholder="Jane Doe" class="p-2" required v-model="name"/>
        <!-- Email -->
        <label class="text-green montserrat-regular pt-3 pb-1 mx-2">Email Address *</label>
        <input type="email" placeholder="janedoe@example.com" class="p-2" required v-model="email" />
        <!-- Phone Number -->
        <label class="text-green montserrat-regular pt-3 pb-1 mx-2">Phone Number</label>
        <input type="text" placeholder="Jane Doe" class="p-2" v-model="phoneNumber" />
        <!-- What's On Your Mind -->
        <label class="text-green montserrat-regular pt-3 pb-1 mx-2">What's On Your Mind</label>
        <textarea v-model="comment" style="height: 150px" />
        <!-- Submit Btn -->
        <div class="submit d-flex justify-content-center">
          <button href="" class="blaked-btn py-2 mt-5">SEND MESSAGE</button>
        </div>
      </form>

      <!-- <p>name: {{ name }}</p> -->

    </div>
  </div>
</template>

<script>

export default {
  name: 'Contact',
  data() {
    return {
      name: '',
      email: '',
      phoneNumber: '',
      comment: ''
    }
  },

  methods: {
    handleSubmit() {
      console.log('form submitted')
      console.log('name: ', this.name)
      console.log('email: ', this.email)
      console.log('phone number: ', this.phoneNumber)
      console.log('comment: ', this.comment)
    }
  }
}
</script>

<style lang="scss" scoped>

.info-business-container {
  background-image: url('../assets/contact-background-mobile.png');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  @media(min-width: 992px) {
    background-image: url('../assets/contact-background-desktop.png');
  }

  .icon {
    height: 25px;
    margin-right: 15px;
  }

  a {
    text-decoration: none;
  }
}

.form-container {

  input, textarea {
    border: 1px solid #114950 !important;
  }

  input::placeholder {
    color: #11484f;
    font-family: "Montserrat";
    font-size: 12px;
    font-weight: 500;
    letter-spacing: 0.17px;
    text-align: left;

    @media(min-width: 992px) {
      font-size: 16px;
    }
  }
}
</style>